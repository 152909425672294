// Wells

.well-variant(@background; @border) {
  background-color: @background;
  border-color: @border;

  hr {
    border-top-color: darken(@border, 5%);
  }
}

